<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Forgot Password" icon=""/>

    <div class="forgotContainer">
        <div class="row">

            <div class="col-12 col-lg-8">
                <div class="forgotBox border shadow-sm">
                    <div v-if="errorStatus === ''" class="row">
                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" placeholder="Email">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImg" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptcha" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="captcha" class="form-control" placeholder="Captcha">
                        </div>

                        <div class="col-lg-9 pb-3">
                            An email will be sent to you for reset password, and it is active for 24 hours
                        </div>

                        <div class="col-lg-3 pb-3 text-end">
                            <button type="button" class="btn btn-secondary" @click="submit">Submit</button>
                        </div>
                    </div>

                    <div v-else class="row">
                        <div v-if="!showResend" class="col-12">
                            <div class="mb-2">Your account is registered but not activated, please activate your account to proceed reset password.</div>
                            <div>If you do not receive activation email, please click <span class="isLink" @click="showResend = true"><u>here</u></span> to send again, wait 5 minutes and check your junk/spam folder.</div>
                        </div>
                        <div v-if="showResend" class="col-12">
                            <div class="row">
                                <div class="col-12 pb-3">
                                    <div class="col-12 pb-2">Fill in detail below to resend activation link</div>
                                </div>
                                <div class="col-12 pb-3">
                                    <input v-model="emailResend" class="form-control" placeholder="Email">
                                </div>

                                <div class="col-12 col-lg-6 pb-3">
                                    <div style="width: 200px; height: 100px">
                                        <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                        <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                            <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                                <fa icon="refresh" />
                                            </Popper>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6 pb-3">
                                    <input v-model="captchaResend" class="form-control" placeholder="Captcha">
                                    <div class="text-end"><button type="button" class="btn btn-secondary mt-4" @click="resendActivation">Resend</button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <div class="row">
                    <div class="col-12">

                        <div class="forgotBoxSm border shadow-sm">
                            <div>Existing user?</div> 
                            <router-link :to="{ name: 'Login', query: route.query}">
                                <div class="greyLink"><u>Login</u></div>    
                            </router-link>
                        </div>

                        <div class="forgotBoxSm border shadow-sm">
                            <div>Need more help?</div>
                            <div>Email to <u>support@signon.my</u></div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Popper from 'vue3-popper'

export default {
    name: 'ForgotPassword',
    components: { TopNavigation, Alert, Popper },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()

        const email = ref(null)
        const captchaImg = ref(null)
        const refId = ref(null)
        const captcha = ref(null)

        const showResend = ref(false)
        const errorStatus = ref('')
        // const errorStatus = ref('no_permission')
        const emailResend = ref(null)
        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const submit = () => {

            const p = {
                email: email.value,
                refId: refId.value,
                captcha: captcha.value,
                frontendUrl: 'https://signon.my/auth/resetPassword'
            }

            console.info('submit p', JSON.stringify(p))
            axios.post('/auth/forgotPassword', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "An reset password email has sent to you, please click the link to reset password."
                        })
                    }
                })
                .catch((error) => {
                    console.log('submit | ERR', JSON.stringify(error))

                    if (error.status === 'no_permission') {

                        errorStatus.value = error.status
                        getCaptchaResend()

                    } else if (error.status === 'user_is_online') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "We found your status is online. You are not allowed to reset password. (" + error.code + ")"
                        })
                    
                    } else if (error.status === 'deleting_user') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "We found that your account is expired. Sorry to inform that we are clearing your account, kindly register a new account again after a minute later. (" + error.code + ")"
                        })

                    } else if (error.status === 'empty_email') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "Please enter email. (" + error.code + ")"
                        })

                    } else if (error.status === 'user_not_found') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "We found that your account is not exists. Kindly register a new account. (" + error.code + ")"
                        })

                    } else if (error.status === 'invalid_whitelabel') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "Domain not found. (" + error.code + ")"
                        })

                    } else if (error.status === 'activation_limit_exceeded') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "We found that your resend activation request is exceeded limit. (" + error.code + ")"
                        })

                    } else if (error.status === 'forgot_password_limit_exceeded') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "We found that your reset password request is exceeded limit. (" + error.code + ")"
                        })

                    } else if (error.status === 'password_limit_exceeded') {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: "We found that your password reset is exceeded limit. (" + error.code + ")"
                        })

                    } else {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.convError(error)
                        })
                    }
                })
        }

        const getCaptcha = () => {
            axios.get('/reference/captcha/FORGOTPWD')
                .then((res) => {
                    if (res.data.status === 1) {
                        captchaImg.value = res.data.data.captcha.image
                        refId.value = res.data.data.refId
                    }
                })
                .catch((error) => {
                    console.log('getCaptcha | ERR', JSON.stringify(error))
                })
        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {
                    console.info('getCaptchaResend', JSON.stringify(res))
                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId
                    }
                })
                .catch((error) => {
                    console.log('getCaptchaResend | ERR', JSON.stringify(error))
                })
        }

        const resendActivation = () => {
            const p = {
                username: emailResend.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: 'https://signon.my/auth/activate'
            } 
            
            console.info('resendActivation', JSON.stringify(p))
            axios.post('/auth/signup/resend')
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Activation email is sent, please wait 5 minutes and check your junk/spam folder."
                        })
                    }
                })
                .catch((error) => {
                    console.log('resendActivation | ERR', JSON.stringify(error))

                    if (error.status === 'captcha_not_match' || error.status === 'ip_not_match' || error.status === 'refId_not_found') {

                        getCaptchaResend()
                        captcha.value = ''

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: 'Captcha not matched. Please refresh the captcha and try again. (' + error.code + ')'
                        })

                    } else if (error.status === 'refId_expired') {

                        getCaptchaResend()
                        captcha.value = ''

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: 'Captcha is expired. Please refresh the captcha and try again. (' + error.code + ')'
                        })

                    } else if (error.status === 'deleting_user') {

                        getCaptchaResend()
                        captchaResend.value = ''

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: 'We found that your account is expired. Sorry to inform that we are clearing your account, kindly register a new account again after a minute later. (' + error.code + ')'
                        })

                    } else if (error.status === 'user_was_activated') {

                        getCaptchaResend()
                        captchaResend.value = ''

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: 'We found that your account is already activated, resend activation is not allowed. (' + error.code + ')'
                        })

                    } else if (error.status === 'user_was_disabled') {

                        getCaptchaResend()
                        captchaResend.value = ''

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: 'We found that your account is disabled, resend activation is not allowed. (' + error.code + ')'
                        })

                    } else if (error.status === 'activation_limit_exceeded') {

                        getCaptchaResend()
                        captchaResend.value = ''

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: 'Resend request is exceeded limit, only 3 requests can be requested within 24 hours. (' + error.code + ')'
                        })

                    } else {
                        alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.convError(error)
                            })
                    }
                })
        }

        onMounted(() => {
            getCaptcha()
        })

        return { 
            alert, route, router, closeAlert, 
            captchaImg, captcha, refId, email, submit, getCaptcha, resendActivation,
            showResend, errorStatus, emailResend, getCaptchaResend, captchaImgResend, refIdResend, captchaResend
        }
    }
}
</script>

<style>
.forgotContainer {
  width: 60%; 
  margin: 105px auto 20px auto;
  border-radius: 10px;
}

.forgotBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
}

.forgotBoxSm {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--auth-box-bg);
}

@media (max-width: 575.98px) {
    .forgotContainer {
        width: 95%;
        margin: 105px auto 20px auto;
    }

    .forgotContainerSm {
        width: 95%;
        margin: 105px auto 20px auto;
        background-color: var(--auth-box-bg);
    }

    .forgotBox {
      border-radius: 10px;
    }
}

</style>